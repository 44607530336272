.new-landing {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-image: linear-gradient(to bottom, #f1f7fe, transparent);
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    padding: 0px 20px;
}

.landing-v1 {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.gap {
    height: 8rem;
}

.new-landing header {
    padding: 0px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f1f7fe;
    z-index: 999;
    height: 78px;
    align-items: center;
}

.new-landing h1 {
    font-size: 5rem;
    color: #1a7efb;
    pointer-events: none;
    height: 120px;
}

.new-landing h1 a,
.new-landing h1 a span,
.new-landing h1 a span .wrap {
    text-decoration: none;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.new-landing h1 a span .cursor {
    font-family: serif;
    font-size: 7rem;
    font-weight: 100;
    color: #c2c2c2;
    animation: blink-animation 1s step-end infinite;
}

@keyframes blink-animation {
    50% {
        opacity: 0;
    }
}

.new-landing h2 {
    color: #084a9a;
    font-size: 2rem;
    font-weight: 600;
}

.new-landing p {
    font-size: 18px;
    color: #606060;
}

.new-landing p.small-font {
    font-size: 14px;
    color: #606060;
}

.new-landing .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.new-landing .signin-btn {
    background-color: #084a9a;
    color: #fff;
    border: none;
    padding: 5px 30px;
    cursor: pointer;
}

.new-landing .signin-btn:hover {
    background-color: #1a7efb;
}

.new-landing .menu-nav-btn {
    display: none;
}

.new-landing header a,
.new-landing header .nav-elem {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #1a7efb;
    position: relative;
    min-height: 37px;
    height: 100%;
}

.new-landing header .drop-down {
    position: absolute;
    top: 76px;
    right: -20px;
    z-index: 9;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 0px;
    flex-direction: row;
    flex-wrap: nowrap;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: none;
}

.new-landing header a:hover label {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.5s;
}

.new-landing header a:hover .drop-down,
.new-landing header .nav-elem:hover .drop-down {
    display: flex;
    transform: translateY(0%);
    animation: menu-animate 0.4s ease-in;
}

@keyframes menu-animate {
    0% {
        transform: translateY(3px);
    }

    100% {
        transform: translateY(0%);
    }
}

.new-landing header .drop-down h2 {
    font-size: 18px;
}

.new-landing header .drop-down h3 {
    color: #084a9a;
}

.new-landing header .drop-down a {
    font-size: 14px;
    color: #002d63;
    width: 100%;
    justify-content: flex-start;
    background-color: #f3f8ff;
    padding: 3px 10px;
    border-radius: 10px;
    /* margin-left: 10px; */
    white-space: nowrap;
    font-weight: 400;
    transition: 0.5s;
}

.new-landing header .drop-down a:hover {
    background-color: #a5c5eb;
    color: #002d63;
    transition: 0.5s;
}

.new-landing header a label,
.new-landing header .nav-elem label {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    padding: 5px 20px;
    border-radius: 10px;
    transition: 0.5s;
}

.new-landing header .drop-down p {
    font-size: 14px;
    font-weight: 400;
}

.new-landing header .left,
.new-landing header .right {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    min-width: 247px;
    width: fit-content;
}

.new-landing header .left {
    background-color: #e7f2ff;
}

.new-landing header .container {
    width: 80%;
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.new-landing .first {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 6rem;
    padding-top: 6rem;
    flex-direction: column;
    /* min-height: ; */
    width: 100%;
}

.new-landing .first .row {
    width: 100%;
}

.new-landing .first .left,
.new-landing .first .right {
    min-width: 250px;
}

.new-landing .first .logos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    mix-blend-mode: multiply;
}

.new-landing .first .logos::-webkit-scrollbar {
    display: none;
    height: 0px;
    width: 0px;
}

.new-landing .first .logos img {
    height: 70px;
    width: auto;
    mix-blend-mode: multiply;
}

.new-landing .first .left h2 {
    max-width: 300px;
}

.new-landing .first .left p {
    max-width: 520px;
    padding: 10px 0px;
}

.new-landing .first .left p a {
    color: #1a7efb;
    text-decoration: none;
    font-weight: 600;
}

.new-landing .first .right {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    flex-direction: column;
    min-width: 520px;
}

.new-landing .first .right .cert {
    max-height: 300px;
    animation: left-slide 1s ease-in-out;
}

@keyframes left-slide {
    0% {
        transform: translateX(200%);
    }

    100% {
        transform: translateX(0%);
    }
}

.new-landing .first .right .animation {
    position: relative;
    width: fit-content;
}

.new-landing .first .right .shield {
    position: absolute;
    height: fit-content;
    width: fit-content;
    bottom: 40%;
    left: -30%;
}

.new-landing .first .right .shield .container {
    position: relative;
    height: 270px;
    width: 240px;
    height: auto;
    margin: 0 auto 40px auto;

    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -ms-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
}

.new-landing .first .right .shield .container .card {
    position: relative;
    width: 100%;
    height: 100%;

    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -ms-transition: -ms-transform 1s;
    -o-transition: -o-transform 1s;
    transition: transform 1s;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.new-landing .first .right .shield .container .card .face {
    position: absolute;
    height: 270px;
    width: 240px;
    top: 0;
    left: 0;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.new-landing .first .right .shield .container .card.flipped,
.new-landing .first .right .shield .container .card .face2 {
    -webkit-transform: rotateY(180deg) translateX(100%);
    -moz-transform: rotateY(180deg) translateX(100%);
    -ms-transform: rotateY(180deg) translateX(100%);
    -o-transform: rotateY(180deg) translateX(100%);
    transform: rotateY(180deg) translateX(100%);
}

.new-landing .first .right .shield .store {
    display: none;
}

.new-landing .first .right .shield .face img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.new-landing .first .right .content .buttons {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.new-landing .first .right .content .buttons input {
    font-size: 12px;
    padding: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #002d63;
    border: none;
}

.new-landing .first .right .content .buttons input:focus {
    border: none;
}

.new-landing .first .right .content .buttons input:hover {
    cursor: pointer;
}

.new-landing .first .right .content .buttons input.active {
    background-color: #1a7efb;
}

.new-landing .first .right .content {
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.new-landing .first .right .content .desc {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    flex-direction: column;
}

.new-landing .first .right .content h2 {
    color: #002d63;
}

.new-landing .first .right .content p {
    text-align: right;
    font-size: 14px;
    color: #002d63;
    max-width: 250px;
    font-weight: 400;
}

.new-landing .first .right .content a {
    font-size: 16px;
    color: #1a7efb;
    text-decoration: none;
}

.new-landing .cta-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.new-landing .cta-btns .create-btn {
    width: fit-content;
    background-color: transparent;
    background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
    color: #fff;
    border: none;
    transition: 0.3s;
    padding: 5px 32px;
    position: relative;
}

.new-landing .cta-btns .create-btn label {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 2px 10px;
    border-radius: 5px;
    background: radial-gradient(
            ellipse farthest-corner at right bottom,
            #fedb37 0%,
            #fdb931 8%,
            #9f7928 30%,
            #8a6e2f 40%,
            transparent 80%
        ),
        radial-gradient(
            ellipse farthest-corner at left top,
            #ffffff 0%,
            #ffffac 8%,
            #d1b464 25%,
            #5d4a1f 62.5%,
            #5d4a1f 100%
        );
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.new-landing .cta-btns .find-btn {
    width: fit-content;
    background-color: #2977c939;
    border: none;
    background-image: linear-gradient(120deg, #084a9a, #1a7efb);
    transition: 0.3s;
    padding: 5px 32px;
}

.new-landing .cta-btns .find-btn:hover,
.new-landing .cta-btns .create-btn:hover {
    scale: 1.05;
    transition: 0.3s;
}

/* ------------------Second----------------- */

.new-landing .second {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8rem;
    flex-direction: column;
    /* min-height: 90vh; */
}

.new-landing .second .banner {
    background-color: #157efb0d;
    min-height: 180px;
    display: flex;
    padding: 10px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    width: 100%;
}

.new-landing .second .banner h2 {
    color: #002d63;
    text-align: center;
}

.new-landing .second .banner h2 span {
    font-size: inherit;
    color: #084a9a;
}

.new-landing .second .banner h3 {
    font-size: 3.3rem;
    color: #084a9a;
    font-weight: 600;
    text-align: center;
}

.new-landing .second .banner h3 span {
    font-size: inherit;
    color: #1a7efb;
}

.new-landing .second .feature {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
}

.new-landing .second .feature .left {
    flex-basis: 35%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    align-self: stretch;
    flex-direction: column;
    pointer-events: none;
}

.new-landing .second .feature .left .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    pointer-events: all;
}

.new-landing .second .feature .left h4 {
    font-size: 1.6rem;
    color: #084a9a;
    font-weight: 400;
    pointer-events: none;
}

.new-landing .second .feature .left p {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    font-size: 16px;
    transition: 0.5s;
    pointer-events: none;
}

.new-landing .second .feature .left .content.active h4 {
    color: #1a7efb;
}

.new-landing .second .feature .left .content.active p {
    height: auto;
    opacity: 1;
    transition: 0.5s;
}

.new-landing .second .feature .left .content:nth-child(1) {
    transform: translateX(20%);
}

.new-landing .second .feature .left .content:nth-child(5) {
    transform: translateX(20%);
}

.new-landing .second .feature .right {
    flex-basis: 60%;
    overflow: visible;
    pointer-events: none;
    position: relative;
    mix-blend-mode: multiply;
}

.new-landing .second .feature .right img {
    width: 120%;
    transform: translate(-20%, 10%);
    mix-blend-mode: multiply;
}

.new-landing .second .feature .right .laptop-screen {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    transform: translate(-22.5%, 29%);
    mix-blend-mode: normal;
    z-index: 1;
    border-radius: 8px;
}

.new-landing .second .feature .middle {
    max-width: 50px;
    position: relative;
    pointer-events: none;
    padding: 20px 0px;
}

.new-landing .second .feature .middle .dots {
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.new-landing .second .feature .middle .dots .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.new-landing .second .feature .middle .dots .wrap .dot {
    padding: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #7db1f0;
}

.new-landing .second .feature .middle .dots .wrap .dot.active {
    background-color: #002d63;
}

.new-landing .second .feature .middle .dots .caret {
    font-size: 2rem;
    color: #8abffd;
}

.new-landing .second .feature .middle .dots .caret i {
    font-size: inherit;
    color: inherit;
}

.new-landing .second .feature .c-shape {
    position: relative;
    height: 600px;
    background-color: transparent;
    border: 1px solid #a6a6a6;
    width: 300px;
    border-radius: 450px 0% 0% 450px;
    border-right: 0px;
    overflow: hidden;
}

.new-landing .second .feature .c-shape .guide {
    position: relative;
    height: 100%;
    background-color: transparent;
    width: 200%;
    overflow: hidden;
    transition: transform 0.5s ease;
}

.new-landing .second .feature .c-shape .guide::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 160px solid transparent;
    border-top: 0;
    border-bottom: 180px solid #1a7efb;
}

.new-landing .second .feature .c-shape .filler {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(2%, 2%);
    height: 96%;
    background-color: #f1f3f6;
    width: 100%;
    border-radius: 450px 0% 0% 450px;
    border-right: 0px;
}

/* ------------------Third----------------- */

.new-landing .third {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 6rem;
    flex-direction: column;
    /* min-height: 70vh; */
}

.new-landing .third h2 {
    font-size: 3rem;
}

.new-landing .third .testimonials {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.new-landing .third .testimonials .container {
    border: 1px solid #1a7efb;
    max-width: 380px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
}

.new-landing .third .testimonials .description,
.new-landing .third .testimonials .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
}

.new-landing .third .testimonials .description p {
    font-size: 14px;
    color: #084a9a;
    font-weight: 400;
}

.new-landing .third .testimonials .user img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.new-landing .third .testimonials .user h3 {
    font-size: 1.4rem;
    color: #002d63;
}

.new-landing .third .testimonials .user p {
    font-size: 12px;
    color: #084a9a;
}

/* ------------------Footer----------------- */

footer {
    background-color: #084a9a;
    width: 100%;
    border-radius: 20px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    margin-bottom: 20px;
}

footer .footer-new {
    display: flex;
    gap: 10px;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    background-image: none;
    color: #fff;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    height: 100%;
    width: 100%;
}

footer .footer-new .logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 15px 20px 15px -12px inset, rgba(0, 0, 0, 0.3) 19px 16px 20px -18px inset;
}

footer .footer-new .logo img {
    width: 60%;
    height: 60%;
    object-fit: contain;
}

footer .footer-new h2 {
    color: #fff;
}

footer .footer-new .wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    max-width: 300px;
}

footer .footer-new .wrap p {
    color: #bedafc;
    font-size: 14px;
}

footer .footer-new .wrap .btns {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    gap: 10px;
}

footer .footer-new .wrap .signin-btn {
    background-color: #002d63;
    width: 100%;
    text-align: left;
    font-size: 16px;
    height: 40px;
}

footer .footer-new .wrap h3 {
    font-size: 1.6rem;
    font-weight: 400;
}

footer .footer-new .wrap a.normal {
    padding: 0px;
}

footer .footer-new .company {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

footer .footer-new .wrap a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    padding-left: 10px;
}

footer .footer-new .for-mobile .logo {
    display: none;
}

/* --------------------Features--------------- */

.landing-v1 section {
    min-height: 100vh;
    width: 100%;
    margin-bottom: 30px;
}

.landing-v1 .heading {
    padding: 30px 0px;
}

.landing-v1 .heading h2 {
    font-size: 3.5rem;
    text-align: center;
    color: #084a9a;
}

.landing-v1 .heading p {
    font-size: 20px;
    color: #002d63;
    font-weight: 600;
    text-align: center;
}

.landing-v1 .features-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding: 10px;
}

.landing-v1 .features-page .bg-pattern {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #9bc3ff38;
    width: 80%;
    height: 80%;
    border-radius: 30px;
}

.landing-v1 .features-page .navigation {
    width: 300px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 30px 20px;
    flex-direction: column;
    position: sticky;
    top: 90px;
    left: 0;
    min-height: 60vh;
}

.landing-v1 .features-page .navigation a:hover {
    padding-left: 15px;
}

.landing-v1 .features-page .navigation a.active:hover {
    padding-left: inherit;
}

.landing-v1 .features-page .navigation a.active {
    display: block;
    background-color: #002d63;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    transition: 0.3s;
}

.landing-v1 .features-page .navigation a {
    display: block;
    background-color: transparent;
    color: #002d63;
    transition: 0.3s;
    width: 100%;
    padding: 0px 10px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;
}

.landing-v1 .features-page .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    max-width: 100%;
}

.landing-v1 .features-page .container .col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem;
    flex-direction: column;
    max-width: 100%;
}

.landing-v1 .features-page .container .col:last-child {
    padding-top: 160px;
}

.landing-v1 .features-page .container .col .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 400px;
    max-width: 100%;
    padding: 0px;
    overflow: hidden;
    background-color: #fff;
}

.landing-v1 .features-page .container .col .wrapper .image {
    height: 260px;
    width: 100%;
    background-color: #a5c5eb;
}

.landing-v1 .features-page .container .col .wrapper .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.landing-v1 .features-page .container .col .wrapper .content {
    padding: 20px 10px;
    max-width: 340px;
    display: block;
    margin: auto;
}

.landing-v1 .features-page .container .col .wrapper .content h3 {
    font-size: 20px;
    color: #084a9a;
    margin-bottom: 10px;
}

.landing-v1 .features-page .container .col .wrapper .content p {
    font-size: 14px;
    color: #668fc1;
}

.landing-v1 .features-page .container .col .wrapper .content a {
    padding: 5px 20px;
    text-decoration: none;
    background-color: #1a7efb;
    color: #fff;
    border-radius: 5px;
    display: block;
    width: fit-content;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.landing-v1 .features-page .container .col .wrapper .content a:hover {
    background-color: #084a9a;
    transition: 0.3s;
}

/* --------------------Pricing--------------- */

.landing-v1 .pricing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.landing-v1 .pricing .toggle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}

.landing-v1 .pricing .toggle p,
.landing-v1 .pricing .toggle i {
    color: #8abffd;
    font-weight: 600;
    padding: 5px 0px;
    transition: 0.3s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.landing-v1 .pricing .toggle p:hover {
    color: #1a7efb;
    transition: 0.3s;
}

.landing-v1 .pricing .toggle p.active {
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 5px 20px;
    color: #084a9a;
}

.landing-v1 .pricing .active .offer {
    transform: translateX(14px);
    transition: 0.3s;
}

.landing-v1 .pricing .offer {
    font-size: 14px;
    color: inherit;
    background-color: #157efb19;
    color: #002d63;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 600;
    transform: translateX(10px);
    transition: 0.3s;
}

.landing-v1 .pricing .container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 820px;
}

.landing-v1 .pricing .container .wrapper {
    max-width: 400px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.landing-v1 .pricing .container .wrapper:hover {
    /* scale: 1.02; */
    transition: 0.3s;
}

.landing-v1 .pricing .container .wrapper h2 {
    font-size: 21px;
    font-weight: 600;
    color: #000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-v1 .pricing .container .wrapper h2 span {
    font-size: 2.5rem;
    font-weight: inherit;
    color: inherit;
    font-family: 'Poppins', sans-serif;
}

.landing-v1 .pricing .container .wrapper h2 span i {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.landing-v1 .pricing .container .wrapper h2 span label {
    display: block;
    text-align: right;
    font-size: 12px;
    line-height: 0px;
    margin-bottom: 20px;
    font-weight: 400;
    color: #a6a6a6;
}

.landing-v1 .pricing .container .wrapper h2 span b {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
}

.landing-v1 .pricing .container .wrapper .pointers,
.landing-v1 .pricing .container .wrapper a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    max-width: 290px;
    width: 100%;
}

.landing-v1 .pricing .container .wrapper .pointers p {
    font-size: 14px;
}

.landing-v1 .pricing .container .wrapper a {
    text-decoration: none;
    color: #1a7efb;
    font-weight: 600;
    padding-left: 12px;
}

.landing-v1 .pricing .container .wrapper .subscribe {
    width: 100%;
    transform: translateX(30px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.landing-v1 .pricing .container .wrapper .subscribe button {
    width: fit-content;
    border-radius: 20px 0px 0px 0px;
    width: 180px;
    padding: 10px;
    background-color: #e9f3ff;
    color: #1a7efb;
    font-weight: 600;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 7px 0px -12px inset, rgba(0, 0, 0, 0.3) 0px 14px 36px -18px inset;
    transition: 0.3s;
    cursor: pointer;
}

.landing-v1 .pricing .container .wrapper .subscribe button:hover {
    width: 200px;
    transition: 0.3s;
}

.landing-v1 .pricing .container .wrapper.premium {
    background-color: #002d63;
}

.landing-v1 .pricing .container .wrapper.premium h2 {
    color: #ffbb00;
}

.landing-v1 .pricing .container .wrapper .pointers p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.landing-v1 .pricing .container .wrapper.premium .pointers p {
    color: #fff;
}

.landing-v1 .pricing .container .wrapper.premium .subscribe button {
    background-color: #fff;
}

.landing-v1 .pricing .container .wrapper.business {
    background-color: #fff2ce;
}

.landing-v1 .pricing .container .wrapper.business .subscribe button {
    background-color: transparent;
    color: #584717;
}

.landing-v1 .comparison table {
    width: 100%;
    background-color: #f9f9f9;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 20px;
}

.landing-v1 .comparison {
    overflow-x: auto;
    border-radius: 20px;
}

.landing-v1 .comparison td,
.landing-v1 .comparison th {
    border-left: 6px solid #f1f4f7;
    border-right: 6px solid #f1f4f7;
    padding: 8px;
    text-align: left;
    min-width: 175px;
}

.landing-v1 .comparison th {
    padding: 20px;
    font-size: 1.8rem;
    color: #1a7efb;
    text-align: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.landing-v1 .comparison td {
    padding: 15px 20px;
    color: #002d63;
    font-size: 16px;
    text-align: center;
}

.landing-v1 .comparison td:first-child {
    max-width: 200px;
    text-align: left;
}

.landing-v1 .comparison td .yes,
.landing-v1 .comparison td .no {
    text-align: center;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 20px;
    width: fit-content;
    background-color: #f0f0f0;
}

.landing-v1 .comparison td .yes {
    background-color: #1a7efb;
    color: #fff;
}

.landing-v1 .comparison th p {
    color: #084a9a;
    font-size: 1.8rem;
}

.landing-v1 .comparison th p b {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
}

.landing-v1 .comparison th p span {
    font-weight: 300;
    font-size: 18px;
}

.landing-v1 .comparison th label {
    display: block;
    margin: auto;
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 10px;
    background-color: #002d63;
    color: #fff;
    width: fit-content;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
}

.landing-v1 .comparison th label.golden {
    background-color: #ffbb00;
    color: #002d63;
}

.landing-v1 .comparison th:first-child,
.landing-v1 .comparison td:first-child {
    border-left: none;
}

.landing-v1 .comparison th:last-child,
.landing-v1 .comparison td:last-child {
    border-right: none;
}

.landing-v1 .comparison tr {
    border: none;
}

.landing-v1 .comparison button.free {
    background-color: #fff;
}

.landing-v1 .comparison td button {
    width: fit-content;
    border-radius: 20px;
    width: fit-content;
    padding: 10px 20px;
    background-color: #157efb0d;
    color: #1a7efb;
    font-weight: 600;
}

.landing-v1 .comparison td button:hover {
    background-color: #bedafc;
}

.landing-v1 .solution {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 20px;
    padding: 3rem 20px;
}

.landing-v1 .solution h1 {
    font-size: 3.2rem;
    height: fit-content;
}

.landing-v1 .solution h2 {
    font-size: 1.6rem;
}

.landing-v1 .solution p {
    font-size: 16px;
}

.landing-v1 .solution .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 10px;
}

.landing-v1 .solution .btn-container button {
    font-size: 16px;
    font-weight: 400;
    padding: 3px 30px;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #1a7efb;
    background-image: linear-gradient(45deg, #084a9a, #1a7efb);
    transition: 0.3s;
    cursor: pointer;
    box-shadow: none;
}

.landing-v1 .solution .btn-container button.alt {
    background-image: none;
    background-color: transparent;
    border: 1px solid #1a7efb;
    color: #1a7efb;
}

.landing-v1 .solution .btn-container button:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: 0.3s;
}

.landing-v1 .solution .content,
.landing-v1 .solution .rep {
    width: 100%;
    max-width: 560px;
    min-width: 280px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.landing-v1 .solution .rep {
    max-width: 400px;
}

.landing-v1 .solution .rep img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    mix-blend-mode: multiply;
}

.landing-v1 .solution.info {
    background-color: #e7f2ff;
    flex-direction: row-reverse;
}

.landing-v1 .solution.info p {
    color: #084a9a;
}

.landing-v1 .solution.alt {
    flex-direction: row-reverse;
}

.landing-v1 .solution li,
.landing-v1 .solution li strong {
    font-size: 16px;
    padding-bottom: 5px;
    color: #606060;
}

.landing-v1 .solution p strong {
    font-size: 16px;
}

.menu-nav-container {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 999;
    padding: 10px;
    transform: translateX(100%);
    transition: 1s;
    background-color: transparent;
    animation: remove-delaynav1 0.3s ease-in-out;
}

@keyframes add-delaynav1 {
    0% {
        background-color: transparent;
    }

    70% {
        background-color: transparent;
    }

    100% {
        background-color: #084a9a69;
    }
}

@keyframes remove-delaynav1 {
    0% {
        background-color: #084a9a69;
    }

    10% {
        background-color: transparent;
    }

    100% {
        background-color: transparent;
    }
}

@keyframes add-delaynav2 {
    0% {
        box-shadow: none;
    }

    70% {
        box-shadow: none;
    }

    100% {
        box-shadow: rgb(145 174 209) 20px 0px 16px 0px, rgb(8 74 154 / 48%) 0px 0px 16px 20px;
    }
}

@keyframes remove-delaynav2 {
    0% {
        box-shadow: rgb(145 174 209) 20px 0px 16px 0px, rgb(8 74 154 / 48%) 0px 0px 16px 20px;
    }

    30% {
        box-shadow: none;
    }

    100% {
        box-shadow: none;
    }
}

.menu-nav-container.active {
    background-color: #084a9a69;
    transform: translateY(0%);
    animation: add-delaynav1 1s ease-in-out;
    transition: 0.6s;
}

.menu-nav-new {
    padding: 2.5rem;
    padding-right: 0px;
    border-radius: 20px;
    max-width: 400px;
    width: 90%;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    box-shadow: none;
    animation: remove-delaynav2 1s ease-in-out;
}

.menu-nav-container.active .menu-nav-new {
    box-shadow: rgb(145 174 209) 20px 0px 16px 0px, rgb(8 74 154 / 48%) 0px 0px 16px 20px;
    animation: add-delaynav2 1s ease-in-out;
}

.menu-nav-new .logo-container {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.menu-nav-new .logo-container .logo,
.menu-nav-new .logo-container .logo img {
    height: 100%;
}

.menu-nav-new .logo-container .menu-close-btn {
    width: fit-content;
    padding: 10px 20px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 60px;
    border-radius: 40px 0px 0px 40px;
    background-color: #e9f3ff;
    color: #002d63;
}

.menu-nav-new .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    flex-grow: 1;
}

.menu-nav-new .container a,
.menu-nav-new .container b {
    width: 100%;
    text-decoration: none;
}

.menu-nav-new .container a label,
.menu-nav-new .container b label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #606060;
}

.menu-nav-new .nav-elem .drop-down {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
}

.menu-nav-new .nav-elem .drop-down .right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    padding-top: 10px;
}

.menu-nav-new .nav-elem .drop-down .right a {
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    color: #002d63;
    font-size: 14px;
}

.menu-nav-new .nav-elem .drop-down .right a.active {
    background-color: #e9f3ff;
}

.menu-nav-new .nav-elem .drop-down .left {
    padding: 20px;
    border-radius: 20px;
    background-color: #e9f3ff;
}

.menu-nav-new .nav-elem .drop-down .left h3 {
    color: #084a9a;
    margin-bottom: 5px;
    font-size: 14px;
}

.menu-nav-new .nav-elem .drop-down .left p {
    font-weight: 400;
    font-size: 12px;
}

.checkout-box {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.checkout-box .items {
    flex-grow: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.checkout-box h2 {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.checkout-box p {
    font-family: inherit;
    font-size: 16px;
}

.checkout-box .wrapper {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.checkout-box .wrapper .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
}

.checkout-box .wrapper .content p.small {
    font-size: 12px;
}

.checkout-box .wrapper .content button {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #bedafc;
    color: #084a9a;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
}

.checkout-box .wrapper img {
    width: 30px;
    height: auto;
}

.checkout-box .wrapper h3 {
    color: #1a7efb;
    font-size: 18px;
    font-weight: 600;
}

.checkout-box .wrapper label {
    font-size: 14px;
    font-weight: 500;
    color: #a6a6a6;
}

.checkout-box label span {
    color: #ff0000;
}

.checkout-box .wrapper input {
    background-color: #f1f1f1;
    margin-top: 10px;
    max-width: 200px;
}

.checkout-box .sub {
    padding-bottom: 20px;
}

.checkout-box .checkout-pricing {
    max-width: 400px;
    min-width: 30%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.checkout-box table {
    width: 100%;
    padding-bottom: 20px;
    border-collapse: collapse;
}

.checkout-box td,
.checkout-box th {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    padding: 5px 0px;
}

.checkout-box td.discount {
    border-top: 1px solid #606060;
    border-bottom: 1px solid #606060;
    color: #1a7efb;
    font-weight: 600;
    padding: 10px 0px;
}

.checkout-box td.label {
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 600;
}

.checkout-box td span {
    background-color: #fdb931;
    color: #000;
    padding: 2px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: fit-content;
    font-size: 12px;
    margin-top: 5px;
}

.checkout-box .checkout-pricing input {
    background-color: #f1f1f1;
}

.checkout-box th {
    font-size: 16px;
}

.checkout-box .astrix {
    font-size: 12px;
    font-weight: 400;
    color: #a6a6a6;
    width: 100%;
}

.checkout-box .input-email {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.checkout-box .input-email input,
.checkout-box input {
    max-width: 400px;
    display: block;
    background-color: #f9f9f9;
}

.checkout-box .input-email .action {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    width: fit-content;
}

.checkout-box .input-email .action i {
    font-size: 21px;
    background-color: #fff;
    color: #2bcead;
    margin-right: 5px;
}

.checkout-box .input-email .action button {
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
}

.checkout-box .promo {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.checkout-box .promo button {
    width: fit-content;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 8px;
    padding: 9px 20px;
}

.checkout-box .warning-small {
    font-size: 14px;
    color: #ff0000;
    font-weight: 500;
}

.modal-otp {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #084a9a69;
    backdrop-filter: blur(5px);
    flex-direction: column;
    gap: 5px;
}

.modal-otp .container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
    background-color: #f1f1f1;
    border-radius: 20px;
    max-width: 400px;
    width: 90%;
}

.modal-otp .container button {
    font-weight: 500;
}

.otp-field .warning-small {
    font-size: 14px;
    color: #ff0000;
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}

.otp-input {
    padding: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-right: 5px;
    font-size: 18px;
}

.otp-input-error {
    border: 2px solid #ff0000;
    animation: error-shake 0.3s ease-in-out;
}

@keyframes error-shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.modal-otp p.small {
    font-size: 14px;
}

.modal-otp .resend-btn {
    width: fit-content;
    padding: 3px 20px;
    background-color: #bedafc;
    color: #1a7efb;
    font-size: 14px;
    font-weight: 500 !important;
    border-radius: 5px;
    cursor: pointer;
}

.modal-otp .cancel-btn {
    width: fit-content;
    padding: 3px 20px;
    background-color: #fff;
    color: #ff0000;
    font-size: 14px;
    font-weight: 500 !important;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 20px;
}

.anim-spin {
    animation: anim-spin 1s linear infinite;
}

@keyframes anim-spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1200px) {
    .new-landing .row {
        justify-content: center;
        padding-bottom: 20px;
    }

    .new-landing .second .feature .left .content:nth-child(1) {
        transform: translateX(15%);
    }

    .new-landing .second .feature .left .content:nth-child(5) {
        transform: translateX(15%);
    }

    .new-landing .second .feature .right {
        flex-basis: 50%;
    }

    .landing-v1 .features-page .navigation {
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .landing-v1 .features-page .container .col:last-child {
        padding-top: 0px;
    }

    .new-landing .pc-only {
        display: none !important;
    }

    .new-landing::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .new-landing .cta-btns {
        gap: 15px;
    }

    .new-landing .header .logo {
        height: 28px;
    }

    .new-landing p {
        font-size: 14px;
    }

    .new-landing p.small-font,
    .new-landing .first .left p a {
        font-size: 12px;
    }

    .new-landing header .container a,
    .new-landing header .container .nav-elem {
        display: none;
    }

    .new-landing header .container {
        justify-content: flex-end;
    }

    .new-landing header .container .menu-nav-btn {
        display: block;
        background-color: transparent;
        color: #002d63;
        padding: 0px;
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-landing header .container .menu-nav-btn i {
        font-size: inherit;
    }

    .new-landing header .container .signin-btn {
        display: block;
    }

    .new-landing .first {
        padding-top: 1rem;
    }

    .new-landing .first .right .animation {
        width: 80%;
    }

    .new-landing .first .right {
        min-width: 250px;
    }

    .new-landing .first .right .cert {
        width: 100%;
    }

    .new-landing .first .right .shield {
        scale: 0.6;
        left: -40%;
    }

    .new-landing .second .banner h2 {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .new-landing .second .banner h3 {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .new-landing .second .banner {
        gap: 10px;
        padding: 40px;
    }

    .new-landing .second .feature .middle {
        max-width: none;
        pointer-events: all;
        width: 100%;
    }

    .new-landing .second .feature .middle .c-shape {
        display: none;
    }

    .new-landing .second .feature .middle .dots {
        display: flex;
    }

    .new-landing .second .feature {
        flex-direction: column-reverse;
        gap: 0px;
    }

    .new-landing .second .feature .left {
        flex-basis: 100%;
        flex-grow: 1;
    }

    .new-landing .second .feature .left .content:nth-child(1) {
        transform: translateX(0%);
    }

    .new-landing .second .feature .left .content:nth-child(5) {
        transform: translateX(0%);
    }

    .new-landing .second .feature .left .content {
        display: none;
    }

    .new-landing .second .feature .left .content.active {
        display: flex;
    }

    .new-landing .second .feature .left h4 {
        font-size: 1.6rem;
        text-align: center;
        width: 100%;
    }

    .new-landing .second .feature .left p {
        text-align: center;
    }

    .new-landing .second .feature .right {
        flex-basis: 100%;
    }

    footer {
        border-radius: 20px;
        padding: 0px;
    }

    footer .footer-new {
        flex-direction: column;
        gap: 40px;
    }

    footer .footer-new .company {
        justify-content: center;
    }

    footer .footer-new .wrap {
        align-items: center;
    }

    footer .footer-new .wrap p {
        text-align: center;
        font-size: 12px;
    }

    footer .footer-new .wrap a {
        padding: 0px;
    }

    .new-landing h1 {
        font-size: 4rem;
        height: auto;
    }

    .new-landing h1 a span .cursor {
        font-size: 6rem;
    }

    .new-landing .first .right .content h2 {
        font-size: 1.3rem;
    }

    .new-landing .first .logos {
        gap: 40px;
        max-width: 90%;
    }

    .new-landing .first .logos img {
        height: 50px;
    }

    .new-landing .third h2 {
        font-size: 2rem;
        text-align: center;
        width: 100%;
    }

    .new-landing .third .testimonials .user h3 {
        font-size: 1.2rem;
    }

    footer .footer-new h2 {
        text-align: center;
    }

    footer .footer-new .for-mobile {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 0px;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-bottom: 20px;
    }

    footer .footer-new .for-mobile .logo {
        display: flex;
        width: 60px;
        height: 60px;
    }

    footer .footer-new .for-mobile .company-details {
        width: calc(100% - 80px);
    }
}
