.editor-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1%;
    flex-grow: 1;
    height: 90%;
    width: 100%;
    overflow-y: clip;
}

.editor-sidenav {
    max-width: 400px;
    width: 27%;
    background-color: transparent;
    padding: 0px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 5%;
}

.editor-sidenav .steps {
    background-color: #fff;
    border-radius: 10px;
    padding: 4%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3vh;
    flex-direction: column;
    width: 22%;
    max-width: 22%;
}

.issue-page {
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 30px;
}

.editor-sidenav .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f1f1f1;
    border-radius: 10px;
    font-size: 1.2rem;
    position: relative;
}

.editor-sidenav .icon i {
    color: #157efb;
    font-size: inherit;
}

.editor-sidenav .step {
    color: #7f8d9e;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-weight: 500;
    position: relative;
    width: 100%;
}

.editor-sidenav .step p {
    font-size: 0.8rem;
}

.step .step-number {
    position: absolute;
    left: -50%;
    top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.step .step-number .round {
    border: 2px solid #939393;
    background-color: #fff;
    color: #939393;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: relative;
    font-size: 0.8rem;
}

.step .step-number hr {
    height: 11vh;
    width: 3px;
    border: none;
    background-color: #939393;
}

.step.active .step-number .round {
    background-color: #157efb;
    border: 2px solid #fff;
    color: #fff;
}

.step.active .step-number hr {
    background-color: #157efb;
}

.step.active .step-number .round .blinker {
    height: 60px;
    width: 60px;
    background-color: #7bb7ff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
    animation-name: blinker;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.selection {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 5vw;
    border-radius: 10px 0px 0px 10px;
    z-index: 0;
}

.step.active .selection {
    background-color: #d0e5fe;
}

.step.active .icon {
    background-color: #d0e5fe;
}

@keyframes blinker {
    0% {
        opacity: 1;
        height: 60px;
        width: 60px;
    }

    50% {
        opacity: 0;
        height: 50px;
        width: 50px;
    }

    100% {
        opacity: 1;
        height: 60px;
        width: 60px;
    }
}

.step-expand {
    background-color: #fff;
    border-radius: 10px;
    width: 73%;
    padding: 15px;
    height: 100%;
    z-index: 3;
    max-width: 73%;
}

.step-expand input {
    font-size: 1rem;
}

.step-expand .template-wrapper .message {
    text-align: center;
    width: 100%;
    color: #707070;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.step-expand .template-wrapper .message span {
    color: #157efb;
}

.step-expand .template-wrapper .message i {
    font-size: 3rem;
    color: #496585;
    padding-top: 10px;
}

.step-expand .wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
}

.step-expand .wrap h3 {
    font-size: 21px;
    font-weight: 400;
}

.step-expand .wrap i {
    font-size: 4rem;
    color: #df7184;
}

.step-expand .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    overflow-y: scroll;
}

.step-expand .container .del-btn {
    display: inline-block;
    background-color: #ff8080;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 0px;
}

.user-uploaded-images {
    flex-grow: 1;
    width: 100%;
}

.user-uploaded-images .user-uploaded-images-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 10px;
}

.user-uploaded-images .image-container {
    width: 60px;
    height: 60px;
    background-color: #eff7ff;
    border: 1px solid #939393;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-uploaded-images .image-container img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
    border-radius: 4px;
}

.user-uploaded-images .image-container .del-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    padding: 0px;
    background-color: #df7184;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    font-size: 10px;
    border-radius: 4px;
}

.user-uploaded-images .image-container .del-btn i {
    font-size: inherit;
    pointer-events: none;
}

.step-expand .container::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.step-expand .add-new {
    background-color: #e1e1e1;
    padding: 6%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: 0.3s;
}

.step-expand .add-new:hover {
    box-shadow: inset 3px 3px 20px #00000021;
    transition: 0.3s;
}

.step-expand .add-new i {
    font-size: 2.6rem;
    color: #707070;
}

.step-expand .sticky-bottom {
    position: sticky;
    bottom: 0px;
    left: 0px;
}

.step-expand .sticky-top {
    position: sticky;
    top: 0px;
    left: 0px;
}

.step-expand .ai i {
    color: #7115fb;
    font-size: 2rem;
}

.step-expand .ai p {
    font-weight: 400 !important;
}

.step-expand .ai h1 {
    font-size: 1rem;
    font-weight: 600;
}

.step-expand .add-new p {
    font-size: 1rem;
    font-weight: 600;
    color: #939393;
}

.step-expand .add-new p.ellipsis {
    max-width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.step-expand .add-new p label {
    background-color: #36454f;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-top: 5px;
    text-align: center;
    pointer-events: none;
}

.step-expand .toggle {
    padding: 0px;
    background-color: #f6f6f6;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.step-expand .toggle .switch {
    width: 50%;
    text-align: center;
    background-color: #f6f6f6;
    font-weight: 600;
    border-radius: 10px;
    padding: 15px;
    font-size: 0.8rem;
}

.step-expand .toggle .switch.active {
    background-color: #d0e5fe;
    color: #157efb;
    font-size: 0.8rem;
}

.step-expand .template-wrapper {
    padding: 0px;
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: scroll;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
}

.step-expand .template-tile {
    height: fit-content;
    width: 120px;
    max-width: none;
    background-color: #eff7ff;
    border-radius: 10px;
    display: block;
    position: relative;
}

.step-expand .template-wrapper::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.step-expand .template-tile img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
}

.help-sec {
    width: 5%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    min-width: 63px;
    position: relative;
}

.help-sec button {
    background-color: #fff;
    color: #157efb;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    padding: 20%;
    font-size: 1rem;
}

.help-sec i {
    font-size: 1.1rem;
}

.help-sec .editor-help-modal {
    position: absolute;
    bottom: 80px;
    right: 110%;
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: none;
}

.help-sec .editor-help-modal .content {
    padding: 10px;
}

.help-sec .editor-help-modal video,
.help-sec .editor-help-modal .video {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 6px;
    position: relative;
}

.help-sec .editor-help-modal .video button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #2bcead;
    font-size: 25px;
    z-index: 3;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.help-sec .editor-help-modal .video button i {
    font-size: inherit;
}

.help-sec .editor-help-modal .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 0px;
}

.help-sec .editor-help-modal .tabs button.active {
    background-color: #157efb;
}

.help-sec .editor-help-modal .tabs button {
    padding: 0px;
    height: 10px;
    width: 10px;
    background-color: #d0e5fe;
    border-radius: 10px;
}

.help-sec .editor-help-modal h3 {
    font-size: 16px;
    color: #157efb;
    font-weight: 600;
    padding-bottom: 5px;
}

.help-sec .editor-help-modal p {
    font-size: 13px;
    color: #707070;
    font-weight: 300;
}

.help-sec .editor-help-modal a {
    font-size: 13px;
    color: #2977c9;
    font-weight: 400;
    text-decoration: none;
    padding-top: 5px;
    display: block;
    width: fit-content;
}

.help-sec .editor-help-modal a i {
    transition: 0.3s;
}

.help-sec .editor-help-modal a:hover i {
    padding-left: 5px;
    transition: 0.3s;
}

.help-sec .editor-help-modal .stage-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    overflow-y: hidden;
    margin-bottom: 10px;
    gap: 10px;
    position: relative;
}

.help-sec .editor-help-modal .close-btn {
    position: absolute;
    top: -36px;
    left: 0px;
    background-color: #df7184;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.help-sec .editor-help-modal .stage-select hr {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    background-color: #157efb;
    z-index: 0;
}

.help-sec .editor-help-modal .stage-select button {
    padding: 0px;
    border-radius: 40px;
    background-color: #ffff;
    border: 1px solid #157efb;
    color: #157efb;
    width: 20px;
    height: 20px;
    z-index: 1;
    font-size: 12px;
    font-weight: 600;
    transition: 0.3s;
    overflow: hidden;
}

.help-sec .editor-help-modal .stage-select button.active {
    background-color: #7bb7ff;
    color: #fff;
}

.help-sec .editor-help-modal .stage-select button p {
    display: none;
}

.help-sec .editor-help-modal .stage-select button label {
    font-size: inherit;
    font-weight: inherit;
}

.help-sec .editor-help-modal .stage-select button:hover label {
    display: none;
}

.help-sec .editor-help-modal .stage-select button:hover p {
    display: block;
    color: #fff;
    font-size: inherit;
    font-weight: inherit;
}

.help-sec .editor-help-modal .stage-select button:hover {
    width: 100px;
    background-color: #157efb;
    transition: 0.3s;
}

.help-sec .editor-help-modal .onboarding-toggle-editor {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    flex-direction: row;
    padding-bottom: 15px;
}

.help-sec .editor-help-modal .onboarding-toggle-editor button {
    padding: 5px 10px;
    background-color: #75c8a7;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    font-weight: 400;
}

.help-sec .editor-help-modal .onboarding-toggle-editor button:last-child {
    background-color: #7115fb;
}

.help-sec .editor-help-modal .onboarding-toggle-editor button:hover {
    background-color: #2bcead;
}

.editor-element {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1%;
    flex-direction: column;
    max-width: 70%;
}

.editor-element .top-sec {
    width: 100%;
    height: 60px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.editor-element .top-sec .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
}

.editor-element .top-sec .container div {
    font-size: inherit;
}

.editor-element .top-sec.recepient-header .container {
    width: 100%;
}

.editor-element .top-sec .preview-btn {
    height: 50px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    border: none;
}

.editor-element .bottom-sec {
    width: 100%;
    padding: 10px;
    height: 60px;
    gap: 10px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editor-element .bottom-sec .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: #939393;
    /* padding-left: 30px; */
}

.editor-element .bottom-sec .container .auto-save {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    font-size: 12px;
}

.editor-element .bottom-sec .container .auto-save.animate {
    animation: cloud-save-animation 0.5s ease-in;
}

@keyframes cloud-save-animation {
    0% {
        padding-left: 0px;
        color: #df7184;
    }

    20% {
        padding-left: 5px;
    }

    40% {
        padding-left: 0px;
    }

    60% {
        padding-left: 5px;
    }

    80% {
        padding-left: 0px;
        color: #df7184;
    }

    100% {
        padding-left: 5px;
        color: #2bcead;
    }
}

.editor-element .bottom-sec .container .manual-save {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    background-color: #2977c9;
    padding: 5px 20px;
    border-radius: 5px;
}

.editor-element .bottom-sec .container .manual-save p,
.editor-element .bottom-sec .container .manual-save i {
    font-size: inherit;
    color: inherit;
}

.editor-element .bottom-sec .save-container {
    flex-direction: column;
    gap: 3px !important;
    position: relative;
}

.editor-element .bottom-sec .container .auto-save p,
.editor-element .bottom-sec .container .auto-save i {
    font-size: inherit;
    color: inherit;
}

.editor-element .bottom-sec .container .container {
    gap: 10px;
}

.editor-element .bottom-sec .container.active {
    color: #2bcead;
}

.editor-element .bottom-sec .container .save-modal {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.editor-element .bottom-sec .container .save-modal button {
    background-color: transparent;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    padding: 5px 10px;
    min-width: 150px;
    color: #157efb;
    height: auto;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    transition: 0.3s;
}

.editor-element .bottom-sec .container .save-modal button:hover {
    background-color: #fff;
    transition: 0.3s;
}

.editor-element .bottom-sec .container .save-modal button label,
.editor-element .bottom-sec .container .save-modal button i {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}

.editor-element .bottom-sec .container .save-modal p {
    color: #707070;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 10px;
}

.editor-element .bottom-sec .undo-delete {
    position: fixed;
    bottom: 60px;
    left: 60px;
    border-radius: 10px;
    z-index: 99;
    padding: 20px 30px;
    background-color: #36454f;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    animation: 0.3s undo-delete ease-in;
    width: 210px;
}

.editor-element .bottom-sec .undo-delete .bar {
    position: absolute;
    background-color: #2bcead;
    height: 6px;
    bottom: -1px;
    left: 0px;
    width: 100%;
    animation-delay: 0.3s;
}

.editor-element .bottom-sec .undo-delete .bar.animated {
    animation: 6s del-progress-bar linear;
}

@keyframes del-progress-bar {
    0% {
        width: 0px;
    }

    100% {
        width: 210px;
    }
}

@keyframes undo-delete {
    0% {
        bottom: -60px;
    }

    100% {
        bottom: 60px;
    }
}

.editor-element .top-sec input,
.editor-element .top-sec select {
    background-color: #f6f6f6;
    color: #000;
    width: fit-content;
}

.editor-element .top-sec input[type='number'] {
    width: 70px;
}

.editor-element .top-sec .image-number {
    width: 90px !important;
}

.editor-element .top-sec .font-edit {
    background-color: transparent;
    color: #000;
    width: fit-content;
    padding: 10px;
    width: 50px;
    height: 50px;
    position: relative;
}

.editor-element .top-sec .font-edit .dropdown {
    position: absolute;
    bottom: -10px;
    transform: translateY(100%);
    left: 0;
    z-index: 3;
    background-color: #fff;
    box-shadow: -6px 6px 20px 0px #00000021;
    padding: 0px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0px;
}

.editor-element .top-sec .image-edit {
    background-color: #f6f6f6;
    color: #000;
    width: fit-content;
    padding: 10px;
    width: 50px;
    height: 50px;
    position: relative;
    transition: 0.3s;
}

.editor-element .top-sec .image-edit:hover {
    background-color: #d1edff;
    color: #157efb;
    transition: 0.3s;
}

.editor-element .top-sec .container label {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 0.8rem;
    color: #939393;
    font-weight: 500;
    padding: 5px;
}

.dropdown li {
    display: flex;
    color: #000;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    flex-direction: row;
    width: 100%;
    font-weight: 400;
}

.dropdown li:hover {
    background-color: #f1f1f1;
}

.editor-element .top-sec i {
    pointer-events: none;
}

.editor-element .top-sec .font-edit.active {
    background-color: #f6f6f6;
}

.editor-element .top-sec input,
.editor-element .top-sec select {
    height: 50px;
    font-size: 1rem;
    padding: 10px;
}

.editor-element .search {
    width: 100%;
    position: relative;
}

.editor-element .search input {
    background-color: #f6f6f6;
    padding: 12px;
    width: 100%;
}

.editor-element .search button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    padding: 8.5px 20px;
    width: fit-content;
    background-color: transparent;
    color: #2977c9;
    transition: 0.3s;
    cursor: pointer;
}

.editor-element .search button:hover {
    background-color: #2977c9;
    color: #fff;
    transition: 0.3s;
}

.italic {
    font-style: italic;
}

.fs-24 {
    font-size: 1rem;
}

.editor-element button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 10px 30px;
    width: fit-content;
}

.editor-element .bottom-sec .previous {
    background-color: #d1edff;
    color: #157efb;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.editor-element .bottom-sec .previous p,
.editor-element .bottom-sec .next p {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

.editor-element .bottom-sec .next {
    background-color: #157efb;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.editor-element .bottom-sec i {
    font-size: 1.2rem;
}

.editor-element .cert-container {
    flex-grow: 1;
    background-color: #c1c1c159;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
    position: relative;
}

.editor-element .cert-container .canvasLoading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #00000069;
    backdrop-filter: blur(10px);
    z-index: 999;
    color: #fff;
    font-size: 3rem;
}

.editor-element .cert-container .canvasLoading.active {
    display: flex;
}

.editor-element .cert-container .message.canvasLoading {
    display: flex;
}

.editor-element .cert-container::-webkit-scrollbar {
    width: 0;
    display: none;
}

.editor-element .cert-container .certificate {
    background-color: transparent;
    /* max-height: 100%; */
    max-height: fit-content;
    width: auto;
    box-sizing: border-box;
    aspect-ratio: 4/3;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-element .cert-container .certificate .wrapper {
    padding: 0px;
    background-color: transparent;
    position: relative;
    display: block;
    /* certificate standard width*/
    /* width: 904px; */
    border-radius: 0px;
}

.editor-element .cert-container .certificate .elements {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.editor-element .cert-container .certificate img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-fit: contain; */
    object-position: center;
}

.sign-container .img-placeholder {
    width: 140px;
    height: 79px;
    display: block;
    margin: auto;
}

.range-slider {
    background-color: #fff;
    border-radius: 10px;
    padding: 10%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.range-slider .recenter {
    padding: 10px 5px;
    background-color: #d1edff;
    border: 2px solid #d1edff;
    color: #157efb;
    border-radius: 10px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    transition: 0.3s;
}

.range-slider .recenter:hover {
    border: 2px solid #157efb;
    transition: 0.3s;
}

.range-slider .recenter.animate {
    animation: hithere 1s ease infinite;
}

@keyframes hithere {
    30% {
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    40%,
    60% {
        transform: rotate(-10deg) scale(1.1);
    }

    50% {
        transform: rotate(10deg) scale(1.1);
    }

    70% {
        transform: rotate(0deg) scale(1.1);
    }

    100% {
        transform: scale(1);
        box-shadow: none;
    }
}

.range-slider .move {
    padding: 0px;
    background-color: #d1edff;
    border: 2px solid #d1edff;
    color: #157efb;
    border-radius: 10px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    transition: 0.3s;
}

.range-slider .move .active {
    background-color: #fff;
    color: #939393;
    padding: 10px 5px;
    border-radius: 10px;
    width: 100%;
    transition: 0.3s;
    font-size: inherit;
}

.range-slider .move .inactive {
    background-color: transparent;
    color: inherit;
    padding: 10px 5px;
    border-radius: 10px;
    width: 100%;
    transition: 0.3s;
    font-size: inherit;
}

.range-slider .input-range {
    height: fit-content;
    width: fit-content;
    border: none;
    border-radius: 5px;
    background: #ccc;
    outline: none;
    writing-mode: bt-lr;
    transform: rotate(-90deg);
}

::-moz-range-track {
    background: #ccc;
    border: 0;
}

input::-moz-focus-inner {
    border: 0;
}

.range-slider .zoom-icon {
    padding: 15px;
    background-color: #f6f6f6;
    color: #000;
    font-size: 1rem;
    color: #157efb;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.text-editor-nav p,
.image-editor-nav p,
.attach-editor-nav p,
.signature-editor-nav p,
.recepient-editor-nav p {
    color: #157efb;
    font-weight: 900;
    font-size: 1rem;
}

.text-editor-nav .attributes {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.text-editor-nav .attributes p {
    color: #939393;
    font-weight: 600;
    font-size: 0.8rem;
}

.text-editor-nav .attributes .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.text-editor-nav .attributes .container.active p {
    color: #000;
    font-weight: 600;
}

.text-editor-nav .attributes button {
    padding: 1px 15px;
    border-radius: 5px;
    background-color: #f6f6f6;
    color: #157efb;
    border: 1px solid #f6f6f6;
    width: fit-content;
    font-size: 0.8rem;
    font-weight: 400;
}

.text-editor-nav .attributes .container.active button {
    background-color: #d0e5fe;
    color: #157efb;
    border: 1px solid #157efb;
}

.text-editor-nav .selected-variables {
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
}

.text-editor-nav .variable {
    color: #000;
    font-weight: 600;
    font-size: 0.8rem;
}

.flex-grow {
    flex-grow: 1;
}

.add-image {
    background-color: #f6f6f6;
    border-radius: 10px;
    border: 2px dashed #939393;
    width: 100%;
    padding: 5%;
}

.add-image h1 {
    font-size: 1rem;
}

.add-image .add-new {
    justify-content: flex-start;
}

.add-image .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.add-image input[type='file'] {
    height: 0;
    width: 0;
    overflow: hidden;
    padding: 0;
    border: none;
}

.add-image input[type='file'] + label {
    background: #496585;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    display: block;
    position: relative;
    padding: 10px 30px;
    text-align: center;
    letter-spacing: normal;
    width: fit-content;
}

.add-image .chosen-image {
    height: 56px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-image .chosen-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
}

.image-editor-nav button {
    padding: 9px 30px;
    width: fit-content;
    display: block;
    margin: 0 auto;
}

.text-editor-nav .table-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    font-size: 0.8rem;
}

.text-editor-nav .table-input div {
    font-size: inherit;
}

.text-editor-nav .table-input input {
    background-color: #f6f6f6;
    padding: 3px 5px;
    border: 1px solid #939393;
    font-size: 0.8rem;
    border-radius: 5px;
    width: 40px;
}

.text-editor-nav .table-input button {
    padding: 1px 15px;
    border-radius: 5px;
    background-color: #f6f6f6;
    color: #157efb;
    border: 1px solid #f6f6f6;
    width: fit-content;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
}

.text-editor-nav .table-input.active button {
    background-color: #d0e5fe;
    color: #157efb;
    border: 1px solid #157efb;
}

.table-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.image-editor-nav .shapes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.image-editor-nav .shapes .container {
    border-radius: 10px;
    background-color: #f1f1f1;
    color: #939393;
    /* width: 150px;
    height: 150px; */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-editor-nav .shapes .hLine {
    width: 70%;
    height: 3px;
    background-color: #707070;
}

.image-editor-nav .shapes .vLine {
    width: 3px;
    height: 70%;
    background-color: #707070;
}

.image-editor-nav .shapes .square {
    width: 60%;
    height: 60%;
    border: 2px solid #707070;
}

.image-editor-nav .shapes .circle {
    width: 60%;
    height: 60%;
    border: 2px solid #707070;
    border-radius: 50%;
}

.add-pdf {
    width: 100%;
}

.add-pdf h1 {
    font-size: 1rem;
}

.add-pdf input[type='file'] {
    height: 0;
    width: 0;
    overflow: hidden;
    padding: 0;
    border: none;
}

.add-pdf input[type='file'] + label {
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    display: block;
    position: relative;
    letter-spacing: normal;
}

.attach-editor-nav .preview {
    border: 2px dashed #939393;
    border-radius: 10px;
    width: 100%;
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #939393;
    font-size: 0.8rem;
    gap: 10px;
}

.attach-editor-nav .preview i {
    font-size: 3rem;
}

.attach-editor-nav object {
    border-radius: 10px;
    border: 2px solid #939393;
}

.signature-editor-nav .add-signature {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.signature-editor-nav .add-signature input {
    background-color: #f6f6f6;
    width: 100%;
    border: 2px solid #f6f6f6;
    font-size: 1rem;
}

.signature-editor-nav .add-signature textarea {
    resize: none;
    height: 90px;
    font-size: 1rem;
    background-color: #f6f6f6;
}

.signature-editor-nav .add-signature button {
    background-color: #f6f6f6;
    color: #2977c9;
    box-shadow: -2px 2px 3px #00000021;
    font-size: 1rem;
    border: 1px solid #157efb;
    transition: 0.3s;
}

.signature-editor-nav .add-signature button:hover {
    box-shadow: none;
    transition: 0.3s;
}

.signature-editor-nav .add-signature .info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0px;
}

.signature-editor-nav .add-signature .info i {
    color: #ff8080;
    font-size: 1rem;
}

.signature-editor-nav .add-signature .info p {
    color: #939393;
    font-weight: 300;
}

.signature-editor-nav .add-signature .info p b {
    font-weight: 600;
    font-size: inherit;
}

.signature-editor-nav .added-signees {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-direction: column;
}

.added-signees .container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    width: 100%;
    flex-direction: row;
    background-color: #496585;
    padding: 15px;
    border-radius: 10px;
    color: #fff;
}

.added-signees .container.active {
    background-color: #2977c9;
}

.added-signees .container.toDelete i {
    animation: deletePrompt 2.5s ease-in-out infinite;
}

@keyframes deletePrompt {
    0% {
        scale: 1;
    }

    5% {
        scale: 1.2;
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(-10deg);
    }

    12% {
        transform: rotate(10deg);
    }

    15% {
        transform: rotate(-10deg);
    }

    17% {
        transform: rotate(10deg);
    }

    20% {
        transform: rotate(-10deg);
    }

    22% {
        transform: rotate(10deg);
    }

    25% {
        scale: 1.2;
        transform: rotate(0deg);
    }

    30% {
        scale: 1;
    }

    100% {
        scale: 1;
    }
}

.added-signees h1 {
    font-weight: 400;
    font-size: 0.8rem;
    color: #fff;
}

.added-signees h1 p {
    font-size: 0.8rem;
    font-weight: 300;
    color: #f1f1f1;
}

.added-signees button {
    padding: 0px;
    width: fit-content;
    background-color: transparent;
}

.added-signees button i {
    color: #fff;
    font-size: 1.4rem;
    transition: 0.3s;
}

.added-signees button:hover i {
    color: #df7184;
    transition: 0.3s;
}

.recepient-editor-nav .add-recepient .info,
.attach-editor-nav .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 0px;
}

.recepient-editor-nav .add-recepient .info.border-box,
.attach-editor-nav .info.border-box {
    border: 1px dashed #ff8080;
    padding: 10px;
    border-radius: 5px;
}

.recepient-editor-nav .add-recepient .info i,
.attach-editor-nav .info i {
    color: #ff8080;
    font-size: 1rem;
}

.recepient-editor-nav .add-recepient .info p,
.attach-editor-nav .info p {
    color: #939393;
    font-weight: 300;
}

.recepient-editor-nav .add-recepient .info p b,
.attach-editor-nav .info p b {
    font-weight: 600;
    font-size: inherit;
}

.recepient-editor-nav b.success {
    font-size: 0.8rem;
    font-weight: 400;
    background-color: transparent;
    color: #2bcead;
}

.recepient-editor-nav b.fail {
    font-size: 0.8rem;
    font-weight: 400;
    background-color: transparent;
    color: #df7184;
}

#format_download {
    background-color: #f6f6f6;
    color: #707070;
    font-size: 1rem;
}

#format_download i {
    font-size: 1rem;
}

.account-container #format_download:hover {
    color: #157efb;
    font-weight: 500;
    cursor: pointer;
}

.pe-none {
    pointer-events: none;
}

.pe-none h1 {
    font-size: 1rem;
}

.pe-none p {
    font-size: 0.8rem;
}

.colorBox {
    color: #000;
}

.w-10px {
    width: 10px;
}

.grid {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 999;
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/50px 50px;
    opacity: 0.2;
}

.grid.p1 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/10px 10px;
}

.grid.p2 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/20px 20px;
}

.grid.p3 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/30px 30px;
}

.grid.p4 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/40px 40px;
}

.grid.p5 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/50px 50px;
}

.grid.p6 {
    background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, #157efb 0) 0 0/60px 60px;
}

.grid-level {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 150px;
    padding: 10px;
    background-color: #fff;
    margin: 10px;
    border-radius: 5px;
    z-index: 2;
}

.grid-level p {
    color: #707070;
    font-size: 12px;
    margin-bottom: 5px;
}

.grid-level input {
    padding: 0px;
}

.grid-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: #d1edff;
    height: 50px;
    padding: 10px 30px;
    border-radius: 10px;
}

.grid-wrapper .history-btn {
    background-color: #fff;
    color: #157efb;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    padding: 0px;
}

.grid-wrapper .buy-credit-btn {
    background-color: #2bcead;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    width: fit-content;
    padding: 0px 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.grid-wrapper h1 {
    color: #157efb;
    font-weight: 600;
}

.grid-wrapper h2 {
    color: #157efb;
    font-size: 1rem;
    font-weight: 600;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border: 2px solid #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    padding: 0px;
    width: auto;
}

.toggle-switch .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.toggle-switch input:checked + .slider {
    background-color: #157efb;
}

.toggle-switch input:focus + .slider {
    box-shadow: 0 0 1px #157efb;
}

.toggle-switch input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.toggle-switch .slider.round {
    border-radius: 34px;
}

.toggle-switch .slider.round:before {
    border-radius: 50%;
}

.recepient-table {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 10px;
}

.recepient-table::-webkit-scrollbar-thumb {
    min-height: 40px;
}

.recepient-table table {
    width: 100%;
}

.recepient-table th {
    background-color: #75c8a7;
    color: #fff;
    padding: 10px 5px;
    font-size: 0.8rem;
    font-weight: 400;
}

.recepient-table input {
    padding: 10px 5px;
    border-radius: 0px;
    border: none;
    background-color: transparent;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
}

.recepient-table td {
    text-align: center;
}

.recepient-table tr {
    background-color: #f1f1f1;
}

.recepient-table tr:nth-child(even) {
    background-color: #d1edff;
}

.recepient-table button {
    padding: 5px 20px;
    height: fit-content;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #d1edff;
    color: #157efb;
    font-size: 0.8rem;
    border: 1px solid #157efb;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.recepient-table .del-btn {
    padding: 0px;
    height: fit-content;
    border-radius: 5px;
    border: none;
    background-color: #df7184;
    color: #fff;
    font-size: 0.5rem;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.recepient-table .del-btn i {
    font-size: inherit;
}

.recepient-table .del-btn:hover {
    background-color: #ff8080;
}

.recepient-table .preview-eye-btn {
    padding: 0px;
    height: fit-content;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    color: #157efb;
    font-size: 1.2rem;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    cursor: pointer;
}

.recepient-table .preview-eye-btn i {
    font-size: inherit;
}

.recepient-table .preview-eye-btn:hover {
    color: #2977c9;
    background-color: transparent;
}

.editor-element .cert-container .back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 15px;
    padding-left: 10px;
    border-radius: 6px;
    background-color: #00000069;
    color: #fff;
    border: none;
    height: auto;
    font-size: 14px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    cursor: pointer;
    z-index: 9;
}

.editor-element .cert-container .back-btn:hover {
    box-shadow: none;
}

.editor-element .cert-container .back-btn i {
    animation: bounce-arrow 3s infinite;
    padding-left: 10px;
    padding-right: 0px;
}

@keyframes bounce-arrow {
    0% {
        padding-left: 10px;
        padding-right: 0px;
    }

    25% {
        padding-left: 0px;
        padding-right: 10px;
    }

    50% {
        padding-left: 10px;
        padding-right: 0px;
    }
}

.editor-element .cert-container .preview-text {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 1px;
    background-color: transparent;
    color: #157efb69;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 20px;
}

.editor-element .cert-container .preview-text:hover {
    color: #157efb;
    background-color: #fff;
}

.editor-element .cert-container .preview-text:hover i {
    color: #2bcead;
    transition: 0.3s;
}

.editor-element .cert-container .preview-text i {
    color: #157efb;
    font-size: 21px;
    transition: 0.3s;
}

.recepient-table button:hover {
    background-color: #157efb;
    color: #fff;
    transition: 0.3s;
}

.recepient-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.recepient-info p {
    color: #707070;
    font-size: 0.8rem;
    font-weight: 400;
}

.recepient-info .validated {
    font-size: 1rem;
    color: #2bcead;
    font-weight: 600;
}

.recepient-info .validated span {
    font-size: 0.8rem;
    color: #2bcead;
    font-weight: 400;
}

.recepient-info .failed-emails {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #df7184;
    width: 100%;
}

.recepient-info .failed-emails p {
    font-size: 0.8rem;
    color: #df7184;
    font-weight: 600;
}

.recepient-info .failed-emails b {
    font-size: 0.8rem;
    color: #707070;
    font-weight: 400;
}

.recepient-info .available-credits,
.recepient-info .used-credits {
    font-size: 0.5rem;
}

.recepient-info .available-credits span {
    color: #157efb;
    font-weight: 600;
    font-size: 0.8rem;
    padding-left: 10px;
}

.recepient-info .used-credits span {
    color: #df7184;
    font-weight: 600;
    font-size: 0.8rem;
    padding-left: 10px;
}

.viewer-wrap {
    width: fit-content;
    position: absolute;
    cursor: default;
    pointer-events: none;
}

.viewer-wrap input,
.viewer-wrap textarea,
.viewer-wrap table,
.viewer-wrap .imagearea {
    background: transparent;
    border: none;
    /* padding: 0px 5px; */
    padding: 0px 2px;
    border-radius: 0px;
    cursor: default;
    resize: none;
    overflow: hidden;
    user-select: none;
}

.viewer-wrap .imagearea {
    padding: 0px;
}

.viewer-wrap .imagearea:hover {
    border: none;
}

.qr-div {
    position: absolute;
    bottom: 0%;
    right: 0%;
    background-color: #fff;
    padding: 30px;
    z-index: 998;
}

.qr-div canvas {
    pointer-events: none;
}

.qr-div .container {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.qr-div img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px !important;
    height: 200px !important;
    object-fit: contain;
    background-color: #fff;
    border-radius: 5px;
    border: none !important;
    padding: 3px;
    pointer-events: none;
}

.QR-move-fadeout {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000069;
    z-index: 997;
    width: 100%;
    height: 100%;
}

#screenshot-div,
.overflow-hidden {
    overflow: hidden;
}

.ai-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000069;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    z-index: 999;
}

.ai-modal .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    z-index: 999;
}

.ai-modal .input-container .input {
    position: relative;
}

.ai-modal .input-container .input .generating {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #939393;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.ai-modal .input-container .input .generating i {
    animation: rotate 3s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ai-modal .input-container input {
    min-width: 500px;
}

.ai-modal .input-container button {
    padding: 11px 30px;
    min-width: 170px;
}

.ai-modal .input-container button.back-btn {
    background-color: #707070;
    color: #fff;
    min-width: auto;
    padding: 11px;
}

.ai-modal .chatgen-container {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    min-width: 770px;
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.ai-modal .chatgen-container textarea {
    padding: 0;
    resize: none;
    height: fit-content;
    border-radius: 0px;
}

.ai-modal .chatgen-container textarea::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.ai-modal .gen-container {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    min-width: 770px;
    opacity: 0;
    height: 0px;
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.ai-modal .gen-container.active {
    opacity: 1;
    height: fit-content;
    pointer-events: all;
}

.ai-modal .gen-container canvas {
    height: 200px;
    width: auto;
    border: 6px solid #ccc;
    border-radius: 10px;
    transition: 0.3s;
    max-width: 278px;
}

.ai-modal .gen-container canvas:hover {
    border-color: #157efb;
    transition: 0.3s;
}

.ai-modal .gen-container h2 {
    width: 100%;
    color: #2977c9;
    font-size: 0.8rem;
    font-weight: 600;
    flex-grow: 1;
}

.saved-content-btn p {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
}

.editor-nosupport {
    display: none;
}

.editor-nosupport i {
    font-size: 6rem;
    padding-bottom: 20px;
}

.editor-nosupport button {
    width: fit-content;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editor-nosupport button i {
    font-size: inherit;
    padding-bottom: 0px;
}

.editor-nosupport.center i {
    padding-bottom: 0px;
}

.editor-nosupport.center {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.saved-content-btn {
    height: 50px !important;
    background-color: #d1edff;
    color: #157efb;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.saved-content-btn:hover {
    background-color: #d0e5fe;
}

.editor-sidenav .sidenav-close-btn {
    display: none;
}

.editor-switch {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    background-color: #fff;
    border: none;
    border-radius: 0px 0px 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 998;
    width: max-content;
}

.editor-switch button {
    padding: 5px 15px;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
    border: 1px solid #157efb;
    line-height: 21px;
    height: 33px;
    background-color: #fff;
    color: #157efb;
    width: fit-content;
    transition: 0.3s;
}

.editor-switch button.inactive,
.editor-switch button.inactive:hover {
    background-color: #f1f1f1;
    color: #939393;
    border: 1px solid #939393;
}

.editor-switch button:hover {
    background-color: #d1edff;
    color: #157efb;
    transition: 0.3s;
}

.editor-switch .active {
    background-color: #157efb;
    color: #fff;
}

.editor-switch-save-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #00000069;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-switch-save-modal .container {
    background-color: #fff;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 5px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 560px;
    gap: 10px;
}

.editor-switch-save-modal .saved-tile {
    height: 120px;
    max-width: fit-content;
    position: relative;
    border: 1px solid #939393;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.3s;
}

.editor-switch-save-modal .saved-tile:hover {
    border: 1px solid #157efb;
    transition: 0.3s;
}

.editor-switch-save-modal .saved-tile:hover button {
    background-color: #157efb;
    transition: 0.3s;
}

.editor-switch-save-modal h2 {
    color: #157efb;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
}

.editor-switch-save-modal .saved-tile img {
    width: auto;
    height: 100%;
}

.editor-switch-save-modal .saved-tile button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    border-radius: 0px;
    background-color: #00000069;
    transition: 0.3s;
}

.editor-guidelines {
    background-color: #2bcead;
    border-radius: 0px;
    position: absolute;
    z-index: 998;
    width: fit-content;
    font-weight: 400;
    border-radius: 5px;
    color: transparent;
    font-size: 2px;
    transition: 0.3s;
    pointer-events: all;
}

.editor-guidelines:hover {
    transition: 0.3s;
    font-size: 12px;
    color: #fff;
    background-color: #2bcead;
}

.editor-guidelines.x {
    top: 50%;
    left: 10px;
    height: fit-content;
    writing-mode: vertical-rl;
    transform: translateY(-50%) rotate(180deg);
    padding: 10px 2px;
}

.editor-guidelines.y {
    top: 10px;
    left: 50%;
    height: fit-content;
    transform: translateX(-50%);
    padding: 2px 10px;
}

.editor-toggle-guidelines {
    background-color: #2bcead;
    color: #fff;
    position: absolute;
    padding: 10px !important;
    top: 5px;
    right: 5px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    width: 35px !important;
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
}

.editor-toggle-guidelines:hover {
    background-color: #2bcead;
}

.finishing-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 10px;
}

.finishing-block {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
    max-height: 400px;
    position: relative;
    overflow: hidden;
}

.finishing-block .coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #00000069;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 10px;
}

.finishing-block .coming-soon p {
    color: #fff;
}

.finishing-block .coming-soon h2 {
    font-size: 16px;
    background-color: #121212;
    padding: 5px 20px;
    border-radius: 10px;
    color: #fff;
}

.finishing-block .input-wrapper input {
    background-color: #f6f6f6;
}

.finishing-block label {
    font-size: 12px;
    color: #707070;
}

.finishing-main {
    height: 100%;
    flex-direction: column !important;
}

.finishing-main .overflow-scroll {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
}

.finishing-main .complete-finishing {
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 10px !important;
    width: 100%;
    align-self: flex-end;
}

.finishing-main .complete-finishing button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.finishing-document {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.finishing-document-upload {
    max-width: 300px;
}

.finishing-document-preview {
    flex-grow: 1;
    max-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.finishing-document-preview .preview {
    height: 100%;
}

.finishing-document-preview .del-btn {
    font-size: 12px;
    font-weight: 600;
    background-color: #df7184;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
}

.finishing-document .info {
    flex-direction: column;
    gap: 10px;
}

.finishing-document .pointers {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #f6f6f6;
}

.finishing-document .pointers .title {
    font-weight: 500;
    font-size: 1rem;
    padding: 0px;
    padding-bottom: 5px;
}

.finishing-document .pointers p {
    font-weight: 300;
    font-size: 12px;
    padding-left: 10px;
}

.editor-signee-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00000069;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.editor-signee-modal .content {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    min-width: 300px;
    max-width: 400px;
}

.editor-signee-modal .content h1 {
    margin-bottom: 15px;
    color: #157efb;
}

.editor-signee-modal .content .container {
    gap: 5px;
}

.editor-signee-modal .content .container textarea {
    resize: none;
}

.editor-signee-modal .content .container p {
    font-size: 12px;
    font-weight: 600;
    color: #707070;
}

.editor-signee-modal .content .container .info {
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed #df7184;
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    gap: 10px;
}

.editor-signee-modal .content .container .info p {
    font-weight: 400;
    font-size: 14px;
}

.editor-signee-modal .content .container .info i {
    color: #df7184;
}

.editor-signee-modal .content .container button {
    height: auto;
    background-color: transparent;
    border: 1px solid #157efb;
    width: fit-content;
    align-self: flex-end;
    color: #157efb;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.editor-signee-modal .content .container button.inactive {
    background-color: #ccc;
    border: 1px solid #ccc;
    color: #fff;
}

.editor-signee-modal .content .container button.inactive:hover {
    background-color: #ccc;
}

.editor-signee-modal .content .container button:hover {
    background-color: #d0e5fe;
}

.editor-signee-modal .content .close-btn {
    background-color: #df7184;
    color: #fff;
    font-size: 12px;
    top: -8px;
    right: -8px;
    cursor: pointer;
    border: 3px solid #f1f1f1;
}

.editor-signee-modal .content .close-btn i {
    font-size: inherit;
    color: inherit;
}

.context-menu {
    position: absolute;
    z-index: 999;
    background-color: #121212;
    color: rgba(255, 255, 255, 0.7);
    min-width: 200px;
    max-width: 300px;
    padding: 20px 0px;
    border-radius: 5px;
    cursor: default;
}

.context-menu ul {
    list-style-type: none;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
    padding: 0px;
}

.context-menu hr {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    border: none;
    background-color: #363636;
    margin: 5px 0px;
}

.context-menu span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.context-menu ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    padding: 5px 20px;
}

.context-menu ul li span.inactive {
    color: #707070;
}

.context-menu ul li .warn {
    background-color: #ff5f15;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
}

.context-menu .submenu {
    display: none;
    position: absolute;
    top: -15px;
    left: 100%;
    background-color: #2f2f2f;
    color: rgba(255, 255, 255, 0.7);
    min-width: none;
    width: 160px;
    max-width: 300px;
    padding: 20px 0px;
    border-radius: 0px 5px 5px 5px;
    cursor: default;
}

.context-menu .submenu hr {
    background-color: #545454;
}

.context-menu li:hover .submenu {
    display: flex;
}

.context-menu ul li:hover {
    color: #fff;
}

@media screen and (max-width: 1760px) {
    .editor-element .bottom-sec .previous p,
    .editor-element .bottom-sec .next p {
        display: none;
    }

    .editor-element .top-sec .image-number {
        width: 50px !important;
    }

    .saved-content-btn p {
        display: none;
    }

    .editor-element .bottom-sec .save-container {
        flex-direction: row-reverse;
        gap: 10px !important;
    }
}

@media screen and (max-width: 1350px) {
    .res-del {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    .res-del {
        display: none !important;
    }
}

/* changed from 1150 to 1420 */
@media screen and (max-width: 1420px) {
    .res-del {
        display: block;
    }

    .editor-sidenav {
        position: fixed;
        top: 0px;
        left: -400px;
        z-index: 999;
        background-color: #f1f1f1;
        min-width: 400px;
        border-radius: 10px;
        border-right: 1px solid #157efb;
        transition: 0.6s;
    }

    .editor-sidenav.active {
        bottom: 0px;
        transition: 0.6s;
        left: 0;
    }

    .editor-sidenav .sidenav-close-btn {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: fit-content;
        transform: translate(110%, -50%);
        height: 60px;
        width: 60px;
        padding: 0px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #df7184;
        transition: 0.3s;
    }

    .editor-sidenav .sidenav-close-btn.menu {
        background-color: #157efb;
        border-radius: 0px 50% 50% 0px;
        transform: translate(90%, -50%);
        transition: 0.3s;
    }

    .step.active .selection {
        border-radius: 10px;
        width: 100%;
    }

    .step .step-number {
        display: none;
    }

    .editor-element {
        max-width: 92%;
    }
}

@media screen and (max-width: 870px) {
    .editor-wrapper {
        display: none;
    }

    .batch-download-wrapper {
        display: none;
    }

    .editor-nosupport {
        display: block;
    }

    .editor-nosupport.center {
        display: flex;
    }

    .editor-switch {
        display: none;
    }

    .download-page-wrapper {
        display: flex;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
    }
}

@media screen and (max-height: 600px) {
    .range-slider {
        gap: 10px;
    }

    .help-sec {
        gap: 5px;
    }
}

@media screen and (max-height: 520px) {
    .editor-wrapper {
        display: none;
    }

    .batch-download-wrapper {
        display: none;
    }

    .editor-nosupport {
        display: block;
    }

    .editor-nosupport.center {
        display: flex;
    }

    .editor-switch {
        display: none;
    }
}

@media screen and (max-height: 760px) {
    .range-slider .input-range {
        display: none;
    }
}
